<template>
  <svg
    id="placeholder"
    xmlns="http://www.w3.org/2000/svg"
    width="35.195"
    height="50.056"
    viewBox="0 0 35.195 50.056"
  >
    <path
      id="Path_22"
      data-name="Path 22"
      d="M174.8,90a8.8,8.8,0,1,0,8.8,8.8A8.809,8.809,0,0,0,174.8,90Zm0,14.684a5.885,5.885,0,1,1,5.876-5.885A5.89,5.89,0,0,1,174.8,104.684Z"
      transform="translate(-157.201 -81.201)"
      fill="#363636"
    />
    <path
      id="Path_21"
      data-name="Path 21"
      d="M93.6,0A17.6,17.6,0,0,0,78.627,26.851L92.6,49.362a1.466,1.466,0,0,0,1.246.693h.012a1.466,1.466,0,0,0,1.246-.713l13.613-22.729A17.6,17.6,0,0,0,93.6,0Zm12.6,25.108L93.82,45.772,81.12,25.306a14.674,14.674,0,1,1,25.077-.2Z"
      transform="translate(-76)"
      fill="#363636"
    />
  </svg>
</template>

<script setup lang="ts"></script>
<style scoped>
svg {
  width: 50px;
}
</style>
